@import url("https://use.typekit.net/jmk3xov.css");
/* *
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    font-family: 'Poppins';
    background: rgb(57, 37, 170);
} */

canvas {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
  }

:root {
    --dark-bg: rgba(15, 15, 15, 0.2);
    --active: #0dcaf0;
    --spacing: 450px;
    font-family: Bebas Neue,sans-serif;
    /* font-family: brandon-grotesque, sans-serif; */
    font-weight: 400;
    font-style: normal;
  }

.footer {
  position:absolute;
  z-index: 1;
  padding-bottom: 20px;
  height: 150px;
  width: 100%;
  background-color: rgb(10,10,10,0.9);
  color: white;
  
}

main {

color: white;
z-index: 1;
position: relative;
width: 100%;
margin: 0px;
padding: 50px;
padding-bottom: 0;

display: grid;
/* grid-template-columns: repeat(12, 1fr); */
}

h1 {
font-size: 4rem;
}

h1, h2, h3, blockquote {
  font-family: Bebas Neue,sans-serif;
/* font-family: elevon, sans-serif; */
font-weight: 900;
font-style: normal;
}
h2, h3{
  color: #0dcaf0;
}



header {
    background: none;
    /* grid-column: 2 / span 6; */
    font-size: 2.5rem;
    /* padding: 2rem; */
    /* margin-bottom: var(--spacing); */
    text-shadow: 2px 2px 2px #000000;
}

section {
    /* grid-column: 2 / 8; */
    padding: 1rem 10px;
    background: var(--dark-bg);
    font-size: 1.25rem;
    line-height: 1.5;
    /* margin-top: var(--spacing); */
    text-shadow: 2px 2px 3px #000000;
}

blockquote {
    margin: 0;
    padding: 0;
    grid-column: 2 / span 9;
    margin-bottom: var(--spacing);
}

blockquote p {
    color: black;
    background-color: rgb(255, 255, 255);
    font-size: 4rem;
    display: inline;
    line-height: 1;
}




/* .webgl
{
position: fixed;
top: 0;
left: 0;
outline: none;
} */


body, html {
    height: 100%;
    text-align: left;
}

body {
  background-color: #242F3F;
}

.loaderwrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    opacity: 1;
    transition: 1s;
}

.loaderwrapper.fade-out{
  opacity: 0;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #Fff;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}
.bg-primary {
  background-color: rgb(10,10,10,0)!important;
}

.btn-info {
  color: #fff;
  background: #afafaf;
  border-color: #ffffff;
  font-weight: 500;
}
/* .btn-info:active{
  background-color: #0dcaf0!important;
} */

.btn-light {
  color: rgb(255, 255, 255);
  background: #1f1f1f;
  border-color: #9e9e9e;
  font-weight: 900;
}

.btn {
  font-size: .9rem;
  padding: 10px 16px;
  transition: all .4s;
  border-radius: 7px;
  border-style: solid;
}


.social-content {
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.avatar {
  border-radius: 20px;
  border: 4px solid #c5c5c5;
  margin-bottom: 10px;
}

.relative {
  position: relative;
}

.hero-1 {
  z-index: 98;
  color: #Fff;
  width: 100%;
  padding-top: 0px;
  position: absolute;
  margin-bottom: 0px!important;
}

.d-sm-inline {
  display: inline!important;
}

a {
  text-decoration: none!important;
  outline: none;
  color: #fff;
  background-color: transparent;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ul>li {
  display: inline;
}

.row>* {
  position: relative;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}


/* Social media buttons */
.fa {
  display: inline-flexbox;
  padding: 12px;
  font-size: 15px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 30px;
  align-content: center;
  background: #818181;
  color: white;
}

.fa:hover {
    opacity: 0.7;
}


/* .timeline ::after{
  position: absolute;
  content: '';
  height:200px;
  width: 4px;
  background: #c5c5c5;
  
} */
.timea::after{
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  transform: translate(-14px, -8px);
}
.timeb::before{
  position: absolute;
  content: '';
  height: 100%;
  width: 4px;
  background: #ffffff;
  transform: translate(-6px, 10px); 
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: rgba(15, 15, 15, 0.5);
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: rgba(168, 168, 168, 0.5);;
  font-weight: 800;
  float: left;

  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-width: 2px;
  border-style: solid;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: var(--active);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}